import React, { Component } from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'


class PageTemplate extends Component {
  render() {
    const { content, title } = this.props.data.wordpressPage;
    return (
      <Layout>
        <article>
          <h1 dangerouslySetInnerHTML={{ __html: title }} />
          <div
            className="wp-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </article>
      </Layout>
    )
  }
}

export default PageTemplate;

export const pageQuery = graphql`
  query currentPageQuery($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
  }
`
